<!--
 * @Author: LazyQ
 * @Date: 2020-04-13 13:21:26
 * @LastEditTime: 2020-04-21 10:24:16
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.delete {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(68, 125, 245, 1);
  &:hover {
    cursor: pointer;
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>

<template>
  <span>
    <span class="delete" @click="openStatistic">统计</span>
    <a-modal
      title="统计项"
      ok-text="确认"
      cancel-text="取消"
      :width="700"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
     <a-table
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :pagination="false"
    >
    </a-table>
    </a-modal>  
  </span>
</template>

<script>
import { getStatistic } from "@/api/project.js";

const columns = [
  {
    title: "总链接数",
    dataIndex: "total_link_count",
  },
  {
    title: "已分配链接数",
    dataIndex: "allocate_count",
  },
  {
    title: "已完成数量",
    dataIndex: "complete_count",
  },
  {
    title: "甄别数量",
    dataIndex: "recognize_count",
  },
  {
    title: "配额满数量",
    dataIndex: "quota_exhausted_count",
  },
  {
    title: "未返回数量",
    dataIndex: "not_return_count",
  }
];

export default {
  props: {
    projectId: undefined
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      loading: false,
      statistic: {},
      data: [],
      columns,
    };
  },
  created() {
  },
  methods: {
    /// 获取区域
    async fetchStatistic() {
      try {
        let res = await getStatistic({
          project_id: this.projectId
        });
        // this.statistic = res
        this.data = [res];
      } catch (error) {
        console.log(error);
      }
    },
    openStatistic() {
      this.visible = true;
      if (this.projectId != undefined) {
        this.fetchStatistic(); 
      }
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },    
  }
};

</script>